import clsx from 'clsx';
import { isFunction, lowerCase, noop } from 'lodash-es';
import { useContext } from 'react';
import type { ModalOverlayProps } from 'react-aria-components';
import { Heading, Modal, ModalOverlay, OverlayTriggerStateContext } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { IconButton } from '../../molecules/icon-button/icon-button';
import { Text } from '../text/text';

export function Drawer({
  children,
  className,
  isDismissable = true,
  isKeyboardDismissDisabled = true,
  onOpenChange,
  ...props
}: ModalOverlayProps) {
  return (
    <ModalOverlay
      className={({ isEntering, isExiting }) =>
        clsx(
          'fixed inset-0 z-50 flex h-[var(--visual-viewport-height)] w-screen items-center justify-center',
          isEntering && 'animate-in fade-in duration-300',
          isExiting && 'animate-out fade-out duration-200',
        )
      }
      data-testid="drawer-overlay"
      isDismissable={isDismissable}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      onOpenChange={(isOpen) => {
        if (!isFunction(onOpenChange)) return;

        setTimeout(() => {
          onOpenChange(isOpen);
        }, 200);
      }}
      style={{ backgroundColor: 'rgba(62, 58, 55, .4)' }}
      {...props}>
      <Modal
        className={(bag) =>
          twMerge(
            'fixed inset-0 z-50 flex flex-col bg-white md:inset-x-auto md:inset-y-0 md:right-0 md:w-[32rem]',
            bag.isEntering && 'animate-in slide-in-from-right duration-300 ease-out',
            bag.isExiting && 'animate-out slide-out-to-right duration-200 ease-in',
            typeof className === 'function' ? className(bag) : className,
          )
        }>
        {children}
      </Modal>
    </ModalOverlay>
  );
}

function Header({
  title,
  subTitle,
  children,
  onBackAction = noop,
  className,
}: {
  children?: React.ReactNode;
  title?: string;
  onBackAction?: () => void;
  subTitle?: string;
  className?: string;
}) {
  const context = useContext(OverlayTriggerStateContext);
  return (
    <header
      className={twMerge(
        'border-grey-300 sticky top-0 z-50 flex items-center justify-between gap-2 border-b bg-white p-4 md:px-6',
        className,
      )}>
      <div
        className={clsx(
          'flex flex-1 flex-col overflow-hidden',
          children ? 'order-2' : 'max-sm:order-2',
        )}>
        <Heading className="text-p3 md:text-h3 truncate font-medium" slot="title">
          {title}
        </Heading>
        {subTitle && (
          <Text className="text-grey-800" slot="description" variant="p6" weight="normal">
            {subTitle}
          </Text>
        )}
      </div>
      <IconButton
        aria-label={title ? `Close ${lowerCase(title)} dialog` : 'Close dialog'}
        className={clsx('hidden md:block', children ? 'order-1 -ml-2' : '-mr-2')}
        icon="close"
        onPress={() => {
          onBackAction();
          context?.close();
        }}
        size="sm"
      />
      <IconButton
        aria-label={title ? `Close ${lowerCase(title)} dialog` : 'Close dialog'}
        className="order-1 -ml-2 md:hidden"
        icon="chevron-left"
        onPress={() => {
          onBackAction();
          context?.close();
        }}
        size="sm"
      />
      {children && <div className="order-3 -mr-2 flex items-center">{children}</div>}
    </header>
  );
}

function Footer({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <footer
      className={clsx(
        'border-grey-300 sticky bottom-0 z-[10] border-t bg-white p-4 pt-3',
        className,
      )}>
      {children}
    </footer>
  );
}

Drawer.Header = Header;
Drawer.Footer = Footer;
